import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "./roboto.css"
import Header from "./newheader"
import Footer from "./footer"

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles"

import { CssBaseline, Container } from "@material-ui/core"

const Layout = ({ children, title, searchBar }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const originTheme = createMuiTheme()
  const theme = responsiveFontSizes(originTheme)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" style={{ minHeight: "70vh" }}>
        <Header
          title={title}
          siteTitle={data.site.siteMetadata?.title || `Title`}
          searchBar={searchBar}
        />
        <main>{children}</main>
      </Container>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
