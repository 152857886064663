import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Divider } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    background:
      "linear-gradient(270deg, rgba(26,88,161,1) 0%, rgba(26,88,161,1) 60%, rgba(231,61,63,1) 60%, rgba(231,61,63,1) 100%)",
    padding: theme.spacing(0.25),
  },
}))

export default function Header(props) {
  const classes = useStyles()
  return <Divider {...props} className={classes.root} />
}
