import React from "react"
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import LinkIcon from "@material-ui/icons/Link"
import W20Divider from "./w20divider"

import Logo from "./logo"

const useStyle = makeStyles(theme => ({
  root: {
    minWidth: "280px",
  },
  logo: {
    justifyContent: "center",
  },
}))

const ListLink = ({ primary, secondary, url }) => {
  const handleClick = e => {
    if (url) {
      window.location.href = url
    }
  }
  return (
    <ListItem button={Boolean(url)} onClick={handleClick}>
      {url ? <ListItemIcon children={<LinkIcon />} /> : <></>}
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  )
}

const Drawer = ({ open, toggle }) => {
  const classes = useStyle()
  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onOpen={toggle(true)}
      onClose={toggle(false)}
    >
      <div role="presentation" onClick={toggle(false)} className={classes.root}>
        <List>
          <ListItem
            className={classes.logo}
            alignItems="center"
            children={<Logo />}
          />
          <W20Divider />
          <ListSubheader children="Navigation" />
          <ListLink
            primary="Proceedings"
            secondary="WWW 2020 proceedings"
            url="/proceedings"
          />
          <ListLink
            primary="Companion Proceedings"
            secondary="WWW 2020 proceedings"
            url="/companion_proceedings"
          />
          <ListLink primary="Schedule" url="/schedule" />
          <ListSubheader children="Utils" />
          <ListLink
            primary="Legacy Page"
            secondary="Request Legacy Page"
            url="./legacy.html"
          />
        </List>
      </div>
    </SwipeableDrawer>
  )
}

export default Drawer
