import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Box, Link } from "@material-ui/core"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo_org.png" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  // console.log(data)
  return (
    <Box mr={2}>
      <Link
        children={
          !data?.placeholderImage?.childImageSharp?.fixed ? (
            "Homepage"
          ) : (
            <Img fixed={data.placeholderImage.childImageSharp.fixed} />
          )
        }
        href="/"
        title="Home | WWW 2020 Info"
      />
    </Box>
  )
}

export default Logo
