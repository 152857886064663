import React, { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import { Box, Divider, useMediaQuery } from "@material-ui/core"
import Logo from "./logo"
import W20Div from "./w20divider"

// Hide On Scroll Setting
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Slide from "@material-ui/core/Slide"

import Drawer from "./drawer"
// import loadable from "@loadable/component"
// const Drawer = loadable(() => import('./drawer'))

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginLeft: "auto",
  },
  header: {
    backgroundColor: "white",
    borderBottomStyle: "none",
  },
}))

export default function Header({ title, siteTitle, searchBar }) {
  const classes = useStyles()
  const theme = useTheme()
  const big = useMediaQuery(theme.breakpoints.up("sm"))
  const [openDrawer, setOpenDrawer] = useState(false)
  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setOpenDrawer(open)
  }
  const trigger = useScrollTrigger()
  return (
    <div className={classes.root}>
      <Drawer open={openDrawer} toggle={toggleDrawer} />
      <Slide appear={false} direction="down" in={!trigger || big}>
        <AppBar
          color="transparent"
          className={classes.header}
          variant="outlined"
        >
          <Toolbar>
            <IconButton
              edge="start"
              children={<MenuIcon />}
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            />
            {big && <Logo />}
            <Typography variant="overline" component="h1">
              <Box fontWeight="bolder" lineHeight="1.1rem">
                {title || "Information"}
              </Box>
            </Typography>
            <Divider flexItem orientation="vertical" variant="middle" />
            <Typography variant="caption" className={classes.title}>
              {siteTitle}
            </Typography>
            {/* {sm ? <></> : searchBar} */}
          </Toolbar>
          <W20Div />
        </AppBar>
      </Slide>
      <Toolbar />
    </div>
  )
}
