import React from "react"
import { Typography, Box, Link, Grid, useMediaQuery } from "@material-ui/core"

import { useTheme } from "@material-ui/core/styles"

const siteMap = {
  Home: {
    href: "/",
    sub: {
      "Web@30 Interview": {
        href: "/schedule/web_at_30_interview/",
      },
    },
  },
  "Official Website": {
    href: "https://www2020.thewebconf.org/",
  },
  Proceedings: {
    sub: {
      Proceedings: {
        href: "/proceedings/",
      },
      "Companion Proceedings": {
        href: "/companion_proceedings/",
      },
    },
  },
  Schedule: {
    sub: {
      Schedule: {
        href: "/schedule/",
      },
      "Poster Demo": {
        href: "/schedule/poster_demo/",
      },
      "Research Track": {
        href: "/schedule/research_track/",
      },
      "How to Attend": {
        href: "/schedule/how_to_attend_the_web_conference_2020_online.pdf",
      },
    },
  },
}

const Second = ({ sub }) => {
  return (
    <ul
      style={{
        paddingLeft: 0,
        marginTop: 0,
      }}
    >
      {Object.entries(sub).map(([key, value]) => {
        return (
          <li key={`${key}-${value}`} style={{ display: "block" }}>
            {value.href ? (
              <Link
                rel="noopener"
                underline="none"
                href={value.href}
                color="textSecondary"
              >
                {key}
              </Link>
            ) : (
                key
              )}
          </li>
        )
      })}
    </ul>
  )
}

const Footer = () => {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div
      style={{
        marginTop: "2rem",
        minHeight: "calc(30vh - 2rem)",
        background: "#f5f5f5",
        borderTop: "solid 5px #eee",
        padding: `2rem ${small ? "1rem" : "10rem"}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container justify="flex-start">
        {Object.entries(siteMap).map(([key, value]) => {
          return (
            <Grid item xs={12} sm={4} lg={3} key={`TL-${key}`}>
              <Typography variant="overline" component="span">
                <Box fontWeight="bolder" fontSize="initial">
                  {
                    value.href ?
                      <Link rel="noopener" underline="none" href={value.href}>
                        {key}
                      </Link> :
                      <Typography variant='inherit'>{key}</Typography>
                  }

                </Box>
              </Typography>
              {value.sub ? <Second sub={value.sub} /> : <></>}
            </Grid>
          )
        })}
      </Grid>
      <Box pt={2} display="flex" justifyContent="center" textAlign="right">
        <Link
          href="https://www2020.thewebconf.org/"
          target="_current"
          rel="noopener noreferer"
          component="a"
          underline="none"
          children="The Web Conference 2020"
        />
        <Typography variant="caption">@TAIPEI</Typography>
      </Box>
    </div>
  )
  // return
}

export default Footer
